import React from "react";
import { Entry } from "./";

function RecentEntries(props) {
  const sortedList = props.entryList.sort(function (x, y) {
    return y.publishDate - x.publishDate;
  });

  return (
    <React.Fragment>
      <h1 className="header text-2xl font-bold header-title mt-10 ">
        Recent Posts
      </h1>
      <div className="flex-col align-center w-full">
        <hr className="mt-4 w-full bg-black h-1"></hr>
        <div className="w-full mx-auto">
          {Object.values(sortedList).map((entry) => (
            <Entry
              title={entry.title}
              description={entry.description}
              entryDate={entry.entryDate}
              id={entry.id}
              link={entry.link}
              publication={entry.publication}
              publishDate={entry.publishDate}
              categoryId={entry.categoryId}
            />
          ))}
        </div>
      </div>
    </React.Fragment>
  );
}

export default RecentEntries;
