import React, { useEffect, useState } from "react";
import { TimeStamps } from "./";
import { BrowserRouter as Router, Link } from "react-router-dom";
import CategoryList from "../components/CategoryList";
import MobileCategoryList from "./MobileCategoryList";

function VideoEmbed(props) {
  const videoList = props.videoList;
  const loadingState = props.loadingState;
  const timeStampList = props.timeStampList;
  const timeStampLoadingState = props.timeStampLoadingState;
  const categoryList = props.categoryList;

  const [vidListState, setvidListState] = useState();
  const [stampListState, setStampListState] = useState();

  useEffect(() => {
    setvidListState(videoList);
  }, [loadingState]);

  useEffect(() => {
    setStampListState(timeStampList);
  }, [timeStampLoadingState]);

  videoList.sort(function (a, b) {
    return new Date(a.meetingDate) - new Date(b.meetingDate);
  });

  let test;
  if (!loadingState && !timeStampLoadingState) {
    // // finds most recent entry
    const recent = videoList.length - 1;

    test = (
      <>
        <div className="h-auto flex-col sm:flex">
          <iframe
            className="w-100 h-80 sm:w-128 sm:h-72 aspect-video"
            frameBorder="0"
            allowFullScreen={true}
            src={videoList[recent].link}
          ></iframe>
          <div className="mt-5 sm:mt-0 mb-5 sm:mb-0">
            {/* <div className="flex">
             <a href={videoList[recent].link} target='blank' className="w-7/12 h-1/4 text-body border cursor-pointer border-black text-black transition duration-500 ease-in-out delay-550 hover:border-orange-400 hover:text-orange-400 font-bold p-1 mr-4">Click here to view video on city council page</a>
             </div> */}
            <div className="flex">
              <Link
                to="/notes"
                className="mt-5 w-7/12 h-1/4 text-body border cursor-pointer border-black text-black transition duration-500 ease-in-out delay-550 hover:border-orange-400 hover:text-orange-400 font-bold p-1 "
                state={{ videoList: vidListState, stampList: stampListState }}
              >
                Click here to view past meetings and notes
              </Link>
		<a class="mt-5 w-7/12 h-1/4 text-body border cursor-pointer border-black text-black transition duration-500 ease-in-out delay-550 hover:border-orange-400 hover:text-orange-400 font-bold p-1" 
		href="https://benknutson.net/files/ANA_2017_to_2022_download.xlsx"> Click here to download campaign contributions report </a>
            </div>
          </div>
        </div>
        <div className="mt-10 sm:mt-0">
          <div className="mt-5">
            {" "}
            <MobileCategoryList categoryList={categoryList} />
          </div>
        </div>
        <TimeStamps
          videoList={videoList}
          loadingState={loadingState}
          timeStampList={timeStampList}
          timeStampLoadingState={timeStampLoadingState}
        />
      </>
    );
  }

  return (
    <>
      <div className="">{!loadingState ? test : null}</div>
    </>
  );
}

export default VideoEmbed;

// VideoEmbed.propTypes
