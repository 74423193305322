import React from "react";
import { useLocation } from "react-router-dom";

function Notes() {
  const videoList = useLocation().state.videoList;
  const stampList = useLocation().state.stampList;

  // const recent = cityCouncil.length - 1;
  const reverse = videoList.slice(0).reverse();

  return (
    <React.Fragment>
      <h1 className="header text-4xl font-bold header-title">
        Past Meetings and Notes
      </h1>
      <hr className="bg-black h-1 mt-4 mb-6"></hr>
      <>
        {Object.values(reverse).map((vid) => {
          return (
            <div>
              <h1 className="font-bold text-2xl">
                <a
                  className="transition duration-500 ease-in-out delay-550 hover:border-orange-400 hover:text-orange-400"
                  href={vid.link}
                  target="_blank"
                >
                  {vid.meetingDate}
                </a>
              </h1>
              <hr className="my-2 h-px bg-gray-200 border-0 dark:bg-gray-700"></hr>
              <ul className="mt-2 mb-6">
                {stampList.map((item) => {
                  if (item.videoId === vid.title) {
                    return (
                      <span className="flex">
                        <strong className="">{item.time}</strong>
                        <p>&nbsp;-&nbsp;</p>
                        <div className="">{item.description}</div>
                      </span>
                    );
                  }
                })}
              </ul>
            </div>
          );
        })}
      </>
    </React.Fragment>
  );
}

export default Notes;
