import React from "react";
import Category from "./Category";
import { useLocation } from "react-router-dom";

function Categories() {
  const categoryList = useLocation().state.categoryList;
  if (categoryList) {
    return (
      <React.Fragment>
        <div className="ml-6 mt-10">
          <h1 className="header text-4xl font-bold header-title">Categories</h1>
          <hr className="bg-black h-1 w-60 mt-4"></hr>
          <ul className="container-md text-lg font-medium text-gray-900 w-56 border-gray-200">
            {Object.values(categoryList).map((item) => (
              <li
                key={item.id}
                className="transition ease-in-out delay-550 hover:text-orange-400 duration-400 py-2 px-4 w-full border-b border-gray-200 cursor-pointer dark:border-gray-600 "
              >
                <Category
                  categoryList={categoryList}
                  title={item.title}
                  id={item.id}
                />
              </li>
            ))}
          </ul>
          <br />
        </div>
      </React.Fragment>
    );
  } else {
    return <p>hello</p>;
  }
}

export default Categories;
