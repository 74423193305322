import React from "react";
import { BrowserRouter as Router, Link } from "react-router-dom";

function MobileCategoryList(props) {
  const categoryList = props.categoryList;

  return (
    <React.Fragment>
      <p className="lg:hidden">
        {" "}
        <Link
          to="/categories"
          className="w-7/12 h-1/4 text-body border cursor-pointer border-black text-black transition duration-500 ease-in-out delay-550 hover:border-orange-400 hover:text-orange-400 font-bold p-1 "
          state={{ categoryList: categoryList }}
        >
          Categories
        </Link>
      </p>
    </React.Fragment>
  );
}

export default MobileCategoryList;
