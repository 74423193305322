import React from "react";
import { VideoEmbed, RecentEntries } from "./";

function Home(props) {
  const videoList = props.videoList;
  const loadingState = props.loadingState;
  const timeStampList = props.timeStampList;
  const timeStampLoadingState = props.timeStampLoadingState;
  const categoryList = props.categoryList;

  return (
    <>
      <div className="p-10">
        <div className="">
          <div className="">
            <VideoEmbed
              videoList={videoList}
              loadingState={loadingState}
              timeStampList={timeStampList}
              timeStampLoadingState={timeStampLoadingState}
              categoryList={categoryList}
            />
            <RecentEntries
              entryList={props.entryList}
              categoryList={props.categoryList}
              loadingState={loadingState}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
