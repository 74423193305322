import React, {useEffect, useState} from 'react';
import { useLocation } from 'react-router-dom';

function CategoryDetails(props) {
  const [categoryEntry, setCategoryEntry] = useState([])
  const location = useLocation();
  const category = location.state.category;
  const entryList = props.entryList;

  useEffect(() => {
    const group = [];
    entryList.forEach(item => {
      if(item.categoryId === category.title){
        group.push(item);
      }
      setCategoryEntry(group);
    })
  }, [category]);

  const sortedList = categoryEntry.sort(function(x, y){
    return y.publishDate - x.publishDate;
  })

  return(
    <React.Fragment>
    <div className='flex'>
    <div className="w-full ml-14">
      <h1 className="font-bold text-4xl">{category.title}</h1>
      <hr className='mt-4 w-3/4 bg-black h-1'></hr>
      <div className='w-3/4'>
        <ol>
        {Object.values(sortedList).map((entry) => (
          <li className='mt-6' key={entry.id}>
            <ul className='m-2' type='1'>
              <li className='font-bold text-2xl '><a className="transition duration-500 ease-in-out delay-550 hover:border-orange-400 hover:text-orange-400" href={entry.link}>{entry.title}</a></li>
              <li className='text-gray-700 text-sm mt-2'>{entry.publication} - {(entry.publishDate).toDate().toLocaleDateString()}</li>
              <li className='text-gray-700'>{entry.description}</li>
            </ul>
            <hr className="my-8 h-px bg-gray-200 border-0 dark:bg-gray-700"></hr>
          </li>
        ))}
        </ol>
      </div>
    </div>
    </div>
    </React.Fragment>
  )
}

export default CategoryDetails;