import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

function Category(props) {
  return (
    <React.Fragment>
      <div>
        <Link to={`/category/${props.title}`} state={{ category: props }}>
          {props.title}
        </Link>
      </div>
    </React.Fragment>
  );
}

export default Category;

Category.propTypes = {
  categoryList: PropTypes.array,
  name: PropTypes.string,
  id: PropTypes.string,
};
