import React from "react";
import { useRef } from "react";
import emailjs from "@emailjs/browser";

function Email() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_pbocp35",
        "template_kh5objd",
        form.current,
        "QrDLGkGpoTwAVtxlg"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };

  return (
    <React.Fragment>
      <div className="flex">
        <div className="flex flex-col w-128 h-screen ml-12">
          <div className="mb-4">
            <h1 className="font-bold text-4xl">Contact Us</h1>
            <hr className="mt-4 w-full bg-black h-1"></hr>
          </div>
          <form ref={form} onSubmit={sendEmail} className="flex-col">
            <input
              type="text"
              name="user_name"
              placeholder="Name"
              required
              className="mb-3 bg-gray-50 border text-gray-900 text-sm focus:ring-orange-400 focus:border-orange-400 block w-full p-2.5  border-gray-600 placeholder-gray-400"
            />
            <input
              type="email"
              name="user_email"
              placeholder="Email"
              required
              className="mb-3 bg-gray-50 border text-gray-900 text-sm focus:ring-orange-400 focus:border-orange-400 block w-full p-2.5  border-gray-600 placeholder-gray-400"
            />
            <textarea
              type="text"
              name="message"
              placeholder="Message"
              required
              className="mb-6 bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block h-2/4 w-full p-2.5  dark:border-gray-600"
            />
            <button
              type="submit"
              value="Send"
              className="border cursor-pointer border-black text-black transition duration-500 ease-in-out delay-550 hover:border-orange-400 hover:text-orange-400 font-bold py-2 px-6 mr-4 header-title"
            >
              {" "}
              Send{" "}
            </button>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Email;
