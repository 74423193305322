import React from "react";
import Category from "./Category";
import { BrowserRouter as Router, Link } from "react-router-dom";

function CategoryList(props) {
  const categoryList = props.categoryList;

  return (
    <React.Fragment>
      <div className="hidden lg:block">
        <h1 className="header text-4xl font-bold header-title">Categories</h1>
        <hr className="bg-black h-1 w-60 mt-4"></hr>
        <ul className="container-md text-lg font-medium text-gray-900 w-56 border-gray-200">
          {Object.values(props.categoryList).map((item) => (
            <li className="transition ease-in-out delay-550 hover:text-orange-400 duration-400 py-2 px-4 w-full border-b border-gray-200 cursor-pointer dark:border-gray-600 ">
              <Category
                categoryList={props.categoryList}
                title={item.title}
                id={item.id}
                key={item.id}
              />
            </li>
          ))}
        </ul>
        <br />
      </div>
      <p className="sm:hidden">
        {" "}
        <Link
          to="/categories"
          className="w-7/12 h-1/4 text-body border cursor-pointer border-black text-black transition duration-500 ease-in-out delay-550 hover:border-orange-400 hover:text-orange-400 font-bold p-1 "
          state={{ categoryListList: categoryList }}
        >
          Categories
        </Link>
      </p>
    </React.Fragment>
  );
}

export default CategoryList;
