// import React from "react";
// import { Home, CategoryDetails, Header, Email, CategoryList, TimeStamps } from './components';
// import {
//   BrowserRouter as Router,
//   Routes,
//   Route,
//   Link
// } from "react-router-dom";

// function App() {
//   return (
//     <Router>
//     <div className="bg-white h-screen">
//       <Header />
//       <div className="flex flex-wrap shrink gap-8 flex-row mx-auto w-11/12 h-screen px-20 pt-10">
//         <div className="grow">
//           <CategoryList />
//         </div>
//         <div className="grow shrink">
//           {/* <Home /> */}
//             <Routes>
//               <Route exact path="/" element={<Home />}/>
//               <Route path="/category/:id" element={<CategoryDetails />}/>
//               <Route path="/contact" element={<Email />}/>
//             </Routes>
//         </div>
//         {/* <div className="grow shrink">
//           <TimeStamps />
//         </div> */}
//       </div>
//       <Link to="/contact">
//         <button className="contactButton border cursor-pointer border-black text-black transition duration-500 ease-in-out delay-550 hover:border-orange-400 hover:text-orange-400 font-bold py-2 px-6 mr-4 ">Contact</button>
//       </Link>
//     </div>
//     </Router>
//   );
// }

// export default App;

import React, { useEffect, useState } from "react";
import {
  collection,
  addDoc,
  onSnapshot,
  setDoc,
  doc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import { db } from "./firebase";
import {
  Home,
  CategoryDetails,
  Header,
  Email,
  CategoryList,
  Notes,
  VideoEmbed,
} from "./components";
import Categories from "./components/Categories";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { deepCopy } from "@firebase/util";

function App() {
  const [categoryList, setCategoryList] = useState([]);
  const [entryList, setEntryList] = useState([]);
  const [timeStampList, setTimeStampList] = useState([]);
  const [videoList, setVideoList] = useState([]);
  const [loadingState, setLoadingState] = useState(true);
  const [timeStampLoadingState, setTimeStampLoadingState] = useState(true);

  //gets categories from db
  useEffect(() => {
    const unSubscribe = onSnapshot(
      collection(db, "category"),
      (collectionSnapshot) => {
        const categories = [];
        collectionSnapshot.forEach((doc) => {
          categories.push({
            title: doc.data().title,
            id: doc.data().id,
          });
        });
        setCategoryList(categories);
      }
    );
    return () => unSubscribe();
  }, []);

  //gets entries from db
  useEffect(() => {
    const unSubscribe = onSnapshot(
      collection(db, "entry"),
      (collectionSnapshot) => {
        const entries = [];
        collectionSnapshot.forEach((doc) => {
          entries.push({
            title: doc.data().title,
            id: doc.data().id,
            publishDate: doc.data().publishDate,
            link: doc.data().link,
            entryDate: doc.data().entryDate,
            description: doc.data().description,
            categoryId: doc.data().categoryId,
            publication: doc.data().publication,
          });
        });
        setEntryList(entries);
      }
    );
    return () => unSubscribe();
  }, []);

  //gets timestamps from db
  useEffect(() => {
    const unSubscribe = onSnapshot(
      collection(db, "timestamps"),
      (collectionSnapshot) => {
        const timestamps = [];
        collectionSnapshot.forEach((doc) => {
          timestamps.push({
            time: doc.data().time,
            id: doc.data().id,
            entryDate: doc.data().entryDate,
            description: doc.data().description,
            videoId: doc.data().videoId,
          });
        });
        setTimeStampList(timestamps);
        setTimeStampLoadingState(false);
      }
    );
    return () => unSubscribe();
  }, []);

  // gets videos from db
  useEffect(() => {
    const unSubscribe = onSnapshot(
      collection(db, "videos"),
      (collectionSnapshot) => {
        const videos = [];
        collectionSnapshot.forEach((doc) => {
          videos.push({
            id: doc.data().id,
            link: doc.data().link,
            meetingDate: doc.data().meetingDate,
            title: doc.data().title,
          });
        });
        setVideoList(videos);
        setLoadingState(false);
      }
    );
    return () => unSubscribe();
  }, []);

  return (
    <Router>
      <Header />
      <div className="bg-white">
        <div className="">
          <div className="lg:grid lg:grid-cols-12 lg:gap-10 lg:px-24 lg:mt-10">
            <div className="lg:col-span-9">
              {/* <Home /> */}
              <Routes>
                <Route
                  exact
                  path="/"
                  element={
                    <Home
                      videoList={videoList}
                      loadingState={loadingState}
                      timeStampList={timeStampList}
                      timeStampLoadingState={timeStampLoadingState}
                      entryList={entryList}
                      categoryList={categoryList}
                    />
                  }
                />
                {/* <Route exact path="/" element={<Home />}/> */}
                <Route
                  path="/category/:id"
                  element={<CategoryDetails entryList={entryList} />}
                />
                <Route path="/contact" element={<Email />} />
                <Route path="/notes" element={<Notes />} />
                <Route path="/categories" element={<Categories />} />
              </Routes>
            </div>
            <div className="hidden md:block md:lg:col-span-3">
              <CategoryList categoryList={categoryList} />
            </div>
          </div>
        </div>
        <div className="text-end fixed bottom-0 right-0">
          <Link to="/contact">
            <button className="border cursor-pointer bg-white border-black text-black transition duration-500 ease-in-out delay-550 hover:border-orange-400 hover:text-orange-400 font-bold py-2 px-6 mr-4 ">
              Contact
            </button>
          </Link>
        </div>
      </div>
    </Router>
  );
}

export default App;
