import React from "react";
import PropTypes from "prop-types";

function Entry(props) {
  const entryDate = new Date(
    props.entryDate.seconds * 1000
  ).toLocaleTimeString();
  const publishDate = props.publishDate.toDate().toLocaleDateString();
  return (
    <React.Fragment>
      <div className="mt-3">
        <ul className="">
          <li className="font-bold text-xl">
            <a
              className="transition ease-in-out delay-550 hover:text-orange-400 duration-400"
              href={props.link}
              target="_blank"
            >
              {props.title}
            </a>
          </li>
          <li className="text-sm mt-1">
            {props.publication} - {publishDate}{" "}
            <span className="text-xs mx-4">{props.categoryId}</span>
          </li>
          <li className=" bodyText">{props.description}</li>
        </ul>
        <hr className="my-2 h-px bg-gray-200 border-0 dark:bg-gray-700"></hr>
      </div>
    </React.Fragment>
  );
}

export default Entry;

Entry.propTypes = {
  title: PropTypes.string,
  id: PropTypes.string,
  publishDate: PropTypes.exact({
    seconds: PropTypes.number,
    nanoseconds: PropTypes.number,
  }),
  entryDate: PropTypes.exact({
    seconds: PropTypes.number,
    nanoseconds: PropTypes.number,
  }),
  description: PropTypes.string,
  link: PropTypes.string,
  publication: PropTypes.string,
  categoryId: PropTypes.string,
};
