import React from 'react';
import { Link } from 'react-router-dom';

function Header() {
  return(
<React.Fragment>
      <div className='flex-row w-full mt-5 header-title text-center pb-5'>
        <h1 className="header text-6xl font-bold"><Link to={`/`} className="transition duration-500 ease-in-out delay-550 hover:border-orange-400 hover:text-orange-400">Anaheim Grease</Link></h1>
        <p className='mb-5 mt-2'>Wheels  <em>and</em>  Palms</p>
        <hr className='m-auto w-11/12 bg-black h-2'></hr>
      </div>
    </React.Fragment>
  )
}

export default Header;

